import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css';
import locale from 'element-plus/lib/locale/lang/zh-cn'

import router from '@/router/index'
import '@/assets/css/reset.less'
import  "@/assets/css/common.less";

import Svgicon from '@/components/Svgicon/index.vue';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'


const app = createApp(App);

app.component("svg-icon",Svgicon)
app.use(router)
app.use(ElementPlus,{locale})

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app')


