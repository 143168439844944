import Cookie from "@/utils/cookie";
import { reactive } from "vue"

const admintokenKey = "pm_admin_token";
const userIdKey = "pm_user_id";
const userTypeKey = "pm_user_type";

const store = {
    state: reactive(
        {
            //登录信息
            admintoken:'',
            userId:'',
            userType:''
          }
    ),


    get userId():string{
        this.state.userId = Cookie.getCookie(userIdKey) as string;
        return this.state.userId;
    },
    set userId(val:string){
        this.state.userId = val;
        Cookie.setCookie(userIdKey,this.state.userId);
    },
    get userType():string{
        this.state.userType = Cookie.getCookie(userTypeKey) as string;
        return this.state.userType;
    },
    set userType(val:string){
        this.state.userType = val;
        Cookie.setCookie(userTypeKey,this.state.userType);
    },

    get admintoken():string{
        this.state.admintoken = Cookie.getCookie(admintokenKey) as string;
        return this.state.admintoken;
    },
    set admintoken(val:string){
        this.state.admintoken = val;
        Cookie.setCookie(admintokenKey,this.state.admintoken);
    },

    cleanLogin():void{
       
        this.state.admintoken='';
        Cookie.cleanCookie(admintokenKey);
    }
  }

export default store
