// import Vue from "vue";

// import Router from "vue-router";

import { createRouter, createWebHashHistory, Router } from 'vue-router'
import store from '../store'


// 路由集合
const routes: Record<string, any> = {
    '/': {
        path: '',
        redirect: '/home'
    }
};
// 辅助函数集
let helpObj: Record<string, any> = {};
// 懒加载路由集合
let requireRouter: __WebpackModuleApi.RequireContext;

/**
 * 自动注册功能
 * @param {function} ctx webpackAsyncContext
 */
function auto(ctx: __WebpackModuleApi.RequireContext) {
    // 保存懒加载
    requireRouter = ctx;
    ctx.keys().forEach((filePath: string) => {

        registered(filePath);
    });

}

/**
 * 路由懒加载
 * @param {string} view 页面地址
 *
 * 参考： https://webpack.docschina.org/api/module-methods/#import-
 */
// const loadView = view => () =>
//     import( /* webpackChunkName: "[request]" */ `@/views/${view}/index2.vue`);

/**
 * 自动注册功能
 * @param {string} r 文件地址
 */
async function registered(r: string) {

    // 过滤已经存在的1级目录
    if (r in helpObj) return;
    // 获取文件地址

    const path = r.endsWith("default.vue") ? r.slice(1) : r.slice(1, -10);
    const meta: any = { auth: true } // 设置当前路由是否需要校验

    if (r.endsWith("route.js")) {
        const { default: main } = await requireRouter(r);
        // 动态添加路由
        myRouter.addRoute(main);
    } else {
        const pathArr = path.split("/");
        const arrLength = pathArr.length;

        if (arrLength > 2) {
            // 处理父级内容
            const arr = pathArr.slice(0, -1);
            // let parentPath = pathArr.slice(0, -1);
            const parentPath = `.${arr.join("/")}/index.vue`;
            // 路由内容
            const route = {
                component: () => requireRouter(r),
                name: '',
                path: '',
            };

            /**
             * 名称规则：父级目录-子级目录
             * 例子：/user/aboutMe -> user-aboutMe
             */
            route.name = pathArr.filter(Boolean).join("-");


            //去掉home目录
            const ar = pathArr.slice(2, pathArr.length);


            route.path = '/' + ar.join('/');


            // 判断辅助函数中有没有父级内容
            if (parentPath in helpObj) {
                const helpObjParent = helpObj[parentPath];

                // 父级是否有 children
                if (helpObjParent.children) {
                    helpObjParent.children.push(route);
                } else {
                    helpObjParent.children = [route];
                }
            }
            // 如果父级不存在
            else {
                // 注册父级
                registered(parentPath);
                // 更新路由
                helpObj[parentPath].children = [route];
            }

            // 增加到辅助函数中
            helpObj[r] = route;
        } else {
            const name = path.slice(1);
            const data = {
                name,
                path,
                meta,
                alias: '',
                component: () => requireRouter(r)
            };
            if (path === "/login") {
                delete data.meta
            }

            routes[path] = data;
            // 增加到辅助函数中
            helpObj[r] = data;
        }
    }
}

// 自动处理懒加载
auto(require.context( // eslint-disable-line
    // 查询路由的目录
    "@/views",
    // 是否查询子目录
    true,
    // 路由匹配规则
    // 对于是以 index2.vue 或是 route.js 结尾的文件
    /(index\.vue$)|(default\.vue$)|(route\.js$)/,
    // 启动懒加载
    "lazy"
));
/**
 * routes数据结构
 * [
 *    {
 *      name: 'home',
 *      path: '/home',
 *      alias: '/'
 *      compoent: fun
 *    },
 *    {
 *      name: 'user',
 *      path: '/user',
 *      compoent: fun,
 *      children: [{
 *          name: 'user-aboutMe',
 *          path: 'about-me',
 *          compoent: fun
 *      }]
 *    },
 * ]
 */
// 路由配置文件

const list = Object.values(routes);
list.push({ path: '/:pathMatch(.*)', redirect: '/404', hidden: true });
const myRouter: Router = createRouter({
    history: createWebHashHistory(),
    // history: createWebHistory(), // 配置单页面的路劲
    routes: list
});
console.log(Object.values(routes))
myRouter.beforeEach((to, from, next) => {
    if (to.matched.some(m => m.meta.auth)) {
        // 对路由进行验证
        if (store.admintoken) { // 已经登陆
            next()   // 正常跳转到你设置好的页面
        } else {
            next({ path: '/login', query: { Rurl: to.fullPath } })
        }
    } else {
        next()
    }
})


// 释放内存空间
helpObj = {};

export { myRouter };

export default myRouter;