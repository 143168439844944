const Cookie={

    setCookie(key:string,value:any,exdays=30){
        const d=new Date();
        d.setTime(d.getTime() + exdays*24*60*60*1000);
        const expires = "expires=" + d.toUTCString();
        document.cookie = key + "=" + value + ";" + expires;

        console.log(document.cookie);
    },

    getCookie(key:string):any{
        const name = key + "=";
        const cookies = document.cookie.split(";");

        for(let i=0;i<cookies.length;i++){
            let c = cookies[i];
            while(c.charAt(0)==" "){
                c = c.substring(1);
            }
            if(c.indexOf(name)!=-1){
                
                return c.substring(name.length,c.length);
            }
        }
        return "";
    },

    cleanCookie(key:string){
        this.setCookie(key,"",-1);
    }
}

export default Cookie